import Vue from 'vue'

const mixin = {
  data() {
    return {
      text: '测试哈哈哈哈'
    }
  },
  mounted() {
    console.log(this.text,'mixin-text');
  }
}



Vue.mixin(mixin)