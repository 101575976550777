<template>
  <div>
    <van-popup
      v-model="showBookkeeping"
      round
      closeable
      position="bottom"
      :style="{ 'min-height': '50%' }"
    >
      <div class="popup-main">
        <div class="top-type-time">
          <div class="type-y">
            <div
              class="type-item"
              :class="[payType == 1 ? 'expend-type' : 'type-item']"
              @click="handleChangePayType(1)"
            >
              支出
            </div>
            <div
              class="type-item"
              :class="[payType == 2 ? 'income-type' : 'type-item']"
              @click="handleChangePayType(2)"
            >
              收入
            </div>
          </div>
          <div
            class="top-time"
            @click="showTimePicker = true"
          >
            <span>{{ typeTime }}</span>
            <span style="margin-left: 3px"><van-icon name="arrow-down" /></span>
          </div>
        </div>
        <div class="filed-input">
          <van-field
            v-model="money"
            type="number"
            label="¥"
          />
        </div>
        <!-- 类型区域 -->
        <div class="type-counter">
          <div
            v-for="item in incomeList"
            :key="item.type_id"
            class="items"
            :class="{
              actived: item.type_id == incomeType && payType == 1,
              activeds: item.type_id == incomeType && payType == 2,
            }"
            @click="handleChangeTypeId(item)"
          >
            {{ item.type_name }}
          </div>
        </div>
        <!-- 备注信息 -->
        <div class="remark">
          <van-field
            v-model="remark"
            rows="2"
            autosize
            type="textarea"
            maxlength="20"
            placeholder="请输入备注"
            show-word-limit
            @focus="handleBlur"
          />
        </div>
        <div
          class="submit-btn"
          @click="handleBlurKey"
        >
          确定
        </div>
      </div>
      <!-- <van-number-keyboard
        v-model="money"
        :show="showKeyboard"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        :hide-on-click-outside="false"
        @blur="handleBlurKey"
      /> -->
    </van-popup>
    <!-- 日期 -->
    <!-- 年月日弹窗 -->
    <van-popup
      v-model="showTimePicker"
      round
      position="bottom"
    >
      <van-datetime-picker
        v-model="currentTimeDate"
        type="date"
        title="选择年月日"
        :min-date="minTimeDate"
        :max-date="maxTimeDate"
        @confirm="handleConfirm"
        @cancel="showTimePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { addBillList, updateBillList } from '@/api/billList';
export default {
  props: {
    popupFlag: {
      type: Boolean,
      default: false,
    },
    billTypeList: {
      type: Array,
      default: () => [],
    },
    detail: {
      type: Object,
      default() {
        return {}
      } 
    }
  },
  data() {
    return {
      payType: 1,
      money: '', // 金额
      showTimePicker: false,
      remark: '', // 备注
      currentTimeDate: new Date(),
      minTimeDate: new Date(2020, 0, 1),
      maxTimeDate: new Date(2030, 10, 1),
      showKeyboard: true,
      typeTime: '',
      incomeList: [],
      incomeType: 0,
      incomeName: '',
      typeDate: '',
      detailAttr: {},
    };
  },
  computed: {
    showBookkeeping: {
      get() {
        return this.popupFlag;
      },
      set(val) {
        this.$emit('update:popupFlag', val);
      },
    },
  },
  watch: {
    billTypeList() {
      this.getIconmeExpend();
    },
    detail: {
      // 监听数据发生变化的处理函数
      handler(newV) {
        this.detailAttr = JSON.parse(JSON.stringify(newV));
      },
      // 是否开启深度监听，由于我们上面props中是一个Object对象，所以需要开启深度监听功能
      deep: true
    }
  },
  mounted() {
    this.typeTime = dayjs(new Date()).format('MM-DD');
    this.typeDate = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
    this.$nextTick(() => {
      this.getIconmeExpend();
    });
    setTimeout(() => {
      this.showDetail()
    },500)
  },
  methods: {
    handleBlur() {
      // this.showKeyboard = false
    },
    showDetail() {
      if(this.detailAttr.id) {
        const detail = this.detailAttr
        this.payType = detail.pay_type
        this.incomeType = detail.type_id
        this.money = detail.money
        this.incomeList = this.billTypeList.filter(
          (item) => item.pay_type == this.payType
        );
        this.typeTime = dayjs(Number(detail.date)).format('MM-DD')
        this.remark = detail.remark
        this.incomeName = detail.type_name
        let currentTime = dayjs(Number(detail.date)).format('YYYY-MM-DD')
        this.currentTimeDate = new Date(currentTime)
        this.typeDate = dayjs(new Date(Number(detail.date))).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    handleConfirm(e) {
      this.typeTime = dayjs(e).format('MM-DD');
      this.typeDate = dayjs(e).format('YYYY-MM-DD HH:mm:ss');
      this.showTimePicker = false;
    },
    validate() {
      if(!this.money) {
        this.$toast('请您输入金额')
        return false
      }
      // if(!this.remark) {
      //   this.$toast('请输入备注信息')
      //   return false
      // }
      return true
    },
    // 点击键盘完成事件
    async handleBlurKey() {
      const validate = await this.validate()
      if(!validate) {
        return
      }
      let timeStamp = new Date(this.typeDate).getTime();
      let money = Number(this.money).toFixed(2);
      const fetch = this.detailAttr.id ? updateBillList : addBillList
      const res = await fetch({
        id: this.detailAttr.id ? this.detailAttr.id : undefined,
        pay_type: this.payType,
        type_id: this.incomeType,
        type_name: this.incomeName,
        remark: this.remark,
        money: money,
        date: timeStamp,
      });
      if(res.code == 200) {
        this.$toast('成功');
        this.showBookkeeping = false;
        this.$emit('updatePopup');
        this.resetForm()
        if(this.detailAttr.id) {
          this.$router.back()
        }
      }

    },
    resetForm() {
      this.money = ''
      this.remark = ''
      this.incomeType = this.incomeList?.[0]?.type_id;
      this.incomeName = this.incomeList?.[0]?.type_name;
      this.typeTime = dayjs(new Date()).format('MM-DD');
    },
    // 改变支出 收入
    handleChangePayType(type) {
      this.payType = type;
      this.getIconmeExpend();
    },
    // 获取收支类型
    getIconmeExpend() {
      let expemnList = this.billTypeList;
      this.incomeList = expemnList.filter(
        (item) => item.pay_type == this.payType
      );
      this.incomeType = this.incomeList?.[0]?.type_id;
      this.incomeName = this.incomeList?.[0]?.type_name
    },
    // 改变类型
    handleChangeTypeId(item) {
      this.incomeType = item.type_id;
      this.incomeName = item.type_name;
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-main {
  margin-top: 35px;
  padding: 10px;
  box-sizing: border-box;
  .top-type-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .type-y {
      display: flex;
      align-items: center;
      gap: 10px;
      .type-item {
        padding: 3px 10px;
        border-radius: 25px;
        font-size: 12px;
        color: #646566;
        background: #f5f5f5;
        border: 1px solid transparent;
      }
      .expend-type {
        border-color: #377df6;
        background: #edfaf6;
        color: #377df6;
      }
      .income-type {
        border-color: #dfc46c;
        background: #faf8f1;
        color: #dfc46c;
      }
    }
    .top-time {
      padding: 3px 8px;
      font-size: 12px;
      background: #f0f0f0;
      border-radius: 25px;
    }
  }
  .filed-input {
    margin-top: 5px;
    border-bottom: 1px solid #e9e9e9;
    ::v-deep .van-cell__title {
      font-size: 30px;
      color: #000;
      font-weight: 600;
      width: 20px;
    }
    ::v-deep .van-field__control {
      font-size: 24px;
    }
  }
  .type-counter {
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    padding: 15px 0;
    .items {
      white-space: nowrap;
      padding: 3px 10px;
      background: #f5f5f5;
      border-radius: 25px;
      font-size: 12px;
      color: #646566;
      border: 1px solid transparent;
      &.actived {
        border-color: #377df6;
        background: #edfaf6;
        color: #377df6;
      }
      &.activeds {
        border-color: #dfc46c;
        background: #faf8f1;
        color: #dfc46c;
      }
    }
  }
  .remark {
    ::v-deep .van-cell {
      border: 1px solid #e9e9e9;
    }
  }
  .submit-btn {
    height: 44px;
    background: #377de8;
    color: #fff;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    border-radius: 5px;
    margin: 40px auto 20px auto;
  }
}
</style>
