/*
 * @Author: 小白
 * @LastEditors: Please set LastEditors
 * @Description:
 */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {},
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});

export default store;
