<template>
  <div id="app">
    <div
      class="view-box"
      :class="{ tabber: $route.meta.tabbar }"
    >
      <!-- https://github.com/vuejs/vue/issues/5524#issuecomment-387271615 -->
      <transition
        name="zoom"
        mode="out-in"
      >
        <keep-alive v-if="$route.meta.keepAlive">
          <router-view :key="$route.meta.key || $route.path" />
        </keep-alive>
        <router-view
          v-else
          :key="$route.meta.key || $route.path"
        />
      </transition>
    </div>
    <van-tabbar
      v-if="$route.meta.tabbar"
      v-model="active"
      route
      :fixed="false"
      class="tabar"
      :safe-area-inset-bottom="false"
    >
      <van-tabbar-item
        icon="description"
        to="/home"
      >
        账单
      </van-tabbar-item>
      <van-tabbar-item
        icon="bar-chart-o"
        to="/statistics"
      >
        统计
      </van-tabbar-item>
      <van-tabbar-item
        icon="contact"
        to="/me"
      >
        我的
      </van-tabbar-item>
    </van-tabbar>
    <div class="ipc">
      <span>
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;font-size:12px"
        >浙ICP备2023015725号-1</a>
      </span>
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33070202100541"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
      ><img
        src="./assets/img/picter.png"
        style="float:left;"
      ><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;font-size:12px">浙公网安备 33070202100541号</p></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      active: 0,
    };
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}
</style>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  $max-width: 500px;

  #app {
    width: $max-width;
    margin: 0 auto;

    .van-nav-bar--fixed,
    .van-popup {
      width: $max-width;
      left: 50%;
      transform: translate(-50%);
    }
  }

  html,
  body {
    background-color: #f5f5f5;
  }
}

.view-box.tabber {
  padding-bottom: 50px;
}
.ipc {
  width: 100%;
  height: 20px;
  background: rgb(248, 248, 248);
  position: fixed;
  bottom: 0;
  z-index: 9999;
  font-size: 13px;
  text-align: center;
  line-height: 20px;
}
.tabar {
  position: fixed;
  left: 0;
  bottom: 20px;
}
</style>

<!-- 此块服务于路由 -->
<style lang="scss">
@keyframes fadeInRight {
  from {
    transform: translate3d(40px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.zoom-enter-active,
.zoom-leave-active {
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
}

.zoom-enter,
.zoom-leave-to {
  opacity: 0;
  transform: scale(0.8) rotateX(-10deg) rotateY(-10deg);
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  // opacity: 0
  opacity: 0;
}
.flip-y-enter-active,
.flip-y-leave-active {
  transition-duration: 0.1s;
  transition-property: all;
  transition-timing-function: ease;
}

.flip-y-enter,
.flip-y-leave-to {
  transform: rotateY(-180deg);
  opacity: 0;
}

.flip-x-enter-active,
.flip-x-leave-active {
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease;
}

.flip-x-enter,
.flip-x-leave-to {
  transform: rotateX(-180deg);
  opacity: 0;
}
.fade-in-right-leave-to {
  opacity: 0.4;
  transition: opacity 0.3s;
}

.fade-in-right-enter {
  opacity: 0.4;
  transform: translate3d(40px, 0, 0);
}

.fade-in-right-enter-to {
  opacity: 0;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
}
</style>
