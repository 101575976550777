import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible/index.js'; // 适配
import router from '@/routers/index'
import store from '@/store/store'
import './mixin/mixin'

import '@/components/index' // 注册公共组件

import Vant from 'vant';
import 'vant/lib/index.css';

import VConsole from 'vconsole'

import vueEsign from 'vue-esign'
Vue.use(vueEsign)

Vue.use(Vant)

// echarts
const echarts = require('echarts')
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.prototype.$store = store;

if (process.env.NODE_ENV != 'production') {
  new VConsole();
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
