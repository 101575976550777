import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    // 首页
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      name: 'Home',
      component: () => import(/* webpackChunkName:"home"*/ '@/views/home/Index'),
      meta: {
        title: '账单',
        tabbar: true,
      },
    },
    {
      path: '/detail',
      name: 'Detail',
      component: () => 
        import(/* webpackChunkName:"home"*/ '@/views/home/Detail'),
      meta: {
        title: '账单详情'
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName:"login"*/ '@/views/login/login'),
      meta: {
        title: '登录'
      }
    },
    // 我的
    {
      path: '/me',
      name: 'Me',
      component: () => import(/* webpackChunkName:"me"*/ '@/views/me/Index'),
      meta: {
        title: '我的',
        tabbar: true,
      },
    },
    {
      path: '/me/person',
      name: 'Person',
      component: () => import(/* webpackChunkName:"updateInfo"*/ '@/views/me/Personal'),
      meta: {
        title: '修改个人信息'
      }
    },
    {
      path: '/reset/password',
      name: 'ResetPassword',
      component: () => import(/* webpackChunkName:"updateInfo"*/ '@/views/me/ResetPassword'),
      meta: {
        title: '修改密码'
      }
    },
    {
      path: '/me/about',
      name: 'About',
      component: () => import(/* webpackChunkName:"about"*/ '@/views/me/About'),
      meta: {
        title: '关于我们'
      }
    },
    {
      path: '/sign/page',
      name: 'SignPage',
      component: () => import(/* webpackChunkName:"sign"*/ '@/views/me/SigntruePage'),
      meta: {
        title: '签字'
      }
    },
    // 统计
    {
      path: '/statistics',
      name: 'Statistics',
      component: () =>
        import(/* webpackChunkName:"statistics"*/ '@/views/statistics/Index'),
      meta: {
        title: '统计',
        tabbar: true,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.path == '/login') {
    return next();
  }
  const token = localStorage.getItem('app_token')
  if (!token) {
    return next('/login')
  }
  // 显示页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '小白记账';
  }
  next();
});

export default router;
