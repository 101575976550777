<template>
  <div>
    <van-popup
      v-model="showPicker"
      round
      position="bottom"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    showType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentDate: new Date(),
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2030, 10, 1),
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.showType
      },
      set(val) {
        this.$emit('update:showType',val)
      }
    }
  },
  mounted() {},
  methods: {
    // 格式化年月
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    onConfirm(e) {
      this.$emit('onConfirm',e)
    }
  }
}
</script>

<style>

</style>