import http from '@/utils/request';

// 添加账单
export function addBillList(data) {
  return http.post('/api/billList/addList', data);
}

// 获取账单列表
export function getBillList(data) {
  return http.get('/api/billList/getList', {
    params: data,
  });
}

// 获取账单详情
export function getBilldetail(id) {
  return http.get(`/api/billList/detailList?id=` + id);
}

// 删除接口
export function deleteBillList(id) {
  return http.delete('/api/billList/deleteList/' + id)
}

// 编辑账单
export function updateBillList(data) {
  return http.put('/api/billList/editList', data)
}