import axios from 'axios';
import { Toast } from 'vant';
import router from '@/routers/index';
import { tansParams } from './common';
Toast.allowMultiple();
const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '' : '/api',
});

const headleError = (error) => {
  Toast(
    error?.response?.data?.msg || error?.msg || error?.message || '未知错误'
  );
  console.debug(error);
};

http.interceptors.request.use(
  (config) => {
    // 是否需要防止数据重复提交
    const isRequestSubmit = (config.headers || {}).repeatSubmit === false;
    // token tenant
    const token = localStorage.getItem('app_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (!isRequestSubmit && (config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      };
      const sessionObj = JSON.parse(sessionStorage.getItem('sessionObj'));
      if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        sessionStorage.setItem('sessionObj', JSON.stringify(requestObj))
      } else {
        const s_url = sessionObj.url; // 请求地址
        const s_data = sessionObj.data; // 请求数据
        const s_time = sessionObj.time; // 请求时间
        const interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
        if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
          const message = '数据正在处理，请勿重复提交';
          return Promise.reject(new Error(message));
        } else {
          sessionStorage.setItem('sessionObj', JSON.stringify(requestObj))
        }
      }
    }
    return config;
  },
  (error) => {
    headleError(error);
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    const resData = response.data;
    if (resData?.code == 200) {
      return resData;
    } else if (resData?.code == 401) {
      headleError(resData);
      localStorage.clear();
      return router.replace('/login').catch(() => {});
    } else {
      headleError(resData);
      return Promise.reject(resData);
    }
  },
  function (error) {
    headleError(error);
    return Promise.reject(error);
  }
);

export default http;
